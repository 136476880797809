.container{
  width: 100%;
  display: flex;
  justify-content: right;
}

.primary-btn{
  background-color: #0A456D;
  color: #fff;
  padding: 0.75rem 2rem;
  border: none;
  outline: none;
  font-weight: bold;
  margin: 1rem 0.5rem;
}
 
.primary-btn:hover{
  background-color: #106197;
}


.secondary-btn{
  background-color: #f8f8f8;
  padding: 0.5em 2rem;

  border: 2px solid;
  border-color: #aeacac;


}

.secondary-btn:hover{
  background-color: #0A456D;
  color: white;
}

.secondary-btn:active{
  background-color: #b8cad5;
  animation-duration: .5s;
  animation-name: blink;
}

.search-options{
  width: 100%;
  margin-bottom: 2rem;
}

.payment{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-bottom: 2rem;
}

.date-time{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  gap: 1rem;
  justify-content: space-between;
}

.btn-grp{
  display: flex;
  gap: 1rem;
}

.search-input{
  width: 565px;
  padding: 0.5rem;
}

.options{
  width: 100%;
  display: flex;
  align-items: left;
  gap: 2rem;
}

.order-status{
  width: 150px;
}

select{
  padding: 0.1rem;
}

.link-view{
  display: flex;
  align-items: center;
}

@font-face {
  font-family: 'YourCustomFont';
  src: url('./fonts/OpenSans.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}


.dateRangePicker{
  width: 65%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.dateLabal{
  display: flex;
  flex-direction: column;
}

.dateLabal small{
  margin-bottom: 0.5rem;
}

.wrap-text-cell {
  max-width: 150px;
  min-width: 120px;
  white-space: noraml;
  word-break: break-word;
}

.wrap-text-cell2 {
  max-width: 250px;
  min-width: 200px;
  white-space: noraml;
  word-break: break-word;
}

.search-section{
  display: flex;
  justify-content: flex-start; 
  gap: 2rem; 
}

@media screen and (max-width: 1100px) and (min-width: 805px){
  .date-time{
    flex-direction: column-reverse;
  }
  .primary-btn {
    width: 250px;
    margin-left: auto;
  }
  .options {
    flex-direction: column; 
    align-items: flex-start; 
  }
  .btn-grp {
    width: 100%; 
    justify-content: flex-start;
  }
  .search-section {
    display: flex; 
    width: 100%;
    justify-content: flex-start; 
    gap: 2rem; 
  }
}

@media screen and (max-width: 805px){

  .date-time{
    flex-direction: column-reverse;
  }

  .dateRangePicker{
    margin-top: 1rem;
    flex-direction: column;
    width: 100%;
  }
  .container{
    flex-direction: column;
  }

  .options{
    flex-direction: column;
    gap: 1rem;
  }
  .search-section {
    flex-direction: column;
    gap: 1rem;
    width: 565px;
  }

  .search-input{
    width: 565px;
  }
}